import React, { useEffect, useRef } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel } from "react-tabs";
import Masonry from "react-masonry-component";
import { SectionWrapper, SectionHeader } from "../visuella.style";
import {
  ReactTabs,
  DesignsWrapper,
  DesignItem,
  DesignItemInner,
} from "./designs.style";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import Link from "common/components/Link";
import { Icon } from "react-icons-kit";
import PropTypes from "prop-types";

const masonryOptions = {
  originTop: true,
};

const Designs = ({ isLatest }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      allVisuellaJson {
        nodes {
          designs {
            title
            subtitle_en
            subtitle_fr
            link
            task_en
            task_fr
            date
            rating
            thumbnail_url {
              image_url
              width
              height
            }
            category_keys
          }
        }
      }
    }
  `);

  const { designs } = data.allVisuellaJson.nodes[0];

  const brandingDesigns = designs.filter((item) =>
    item.category_keys.includes("branding")
  );
  const filteredBrandingDesigns = brandingDesigns.slice(
    0,
    isLatest ? 6 : brandingDesigns.length
  );

  const packagingDesigns = designs.filter((item) =>
    item.category_keys.includes("packaging")
  );
  const filteredPackagingDesigns = packagingDesigns.slice(
    0,
    isLatest ? 6 : packagingDesigns.length
  );

  const communicationDesigns = designs.filter((item) =>
    item.category_keys.includes("communication")
  );
  const filteredCommunicationDesigns = communicationDesigns.slice(
    0,
    isLatest ? 6 : communicationDesigns.length
  );

  const mediaDesigns = designs.filter((item) =>
    item.category_keys.includes("media")
  );
  const filteredMediaDesigns = mediaDesigns.slice(
    0,
    isLatest ? 6 : mediaDesigns.length
  );

  return (
    <SectionWrapper
      id="design"
      style={{
        "--paddingTop": isLatest ? "10px" : "100px",
        "--paddingBottom": "60px",
      }}
    >
      <Container>
        <SectionHeader>
          <Heading
            as="h5"
            content={t(
              isLatest ? "designs.latestDesignsTitle" : "designs.title"
            )}
          />
          <Heading content={t("designs.subtitle")} />
        </SectionHeader>

        <ReactTabs>
          <nav>
            <TabList>
              <Tab key={"branding"}>{t(`designs.branding`)}</Tab>
              <Tab key={"packaging"}>{t(`designs.packaging`)}</Tab>
              <Tab key={"communication"}>{t(`designs.communication`)}</Tab>
              <Tab key={"media"}>{t(`designs.media`)}</Tab>
            </TabList>
          </nav>
          <TabPanel key={"branding"}>
            <DesignsWrapper>
              <Masonry className="masonryGrid" options={masonryOptions}>
                {filteredBrandingDesigns.map(
                  ({ title, link, thumbnail_url }, index) => (
                    <DesignItem
                      key={index}
                      onClick={() => {
                        navigate(`/designs/${link}`);
                      }}
                    >
                      <DesignItemInner>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            paddingBottom: `${
                              (thumbnail_url.height / thumbnail_url.width) * 100
                            }%`,
                          }}
                        >
                          <img
                            src={thumbnail_url.image_url}
                            alt={title}
                            sizes="(max-width: 350px) 350px, (max-height: 400px) 400px"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "10px 10px 0 0",
                              display: "block",
                            }}
                            loading="lazy"
                          />
                        </div>
                      </DesignItemInner>
                    </DesignItem>
                  )
                )}
              </Masonry>
            </DesignsWrapper>
          </TabPanel>
          <TabPanel key={"packaging"}>
            <DesignsWrapper>
              <Masonry className="masonryGrid" options={masonryOptions}>
                {filteredPackagingDesigns.map(
                  ({ title, link, thumbnail_url }, index) => (
                    <DesignItem
                      key={index}
                      onClick={() => {
                        navigate(`/designs/${link}`);
                      }}
                    >
                      <DesignItemInner>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            paddingBottom: `${
                              (thumbnail_url.height / thumbnail_url.width) * 100
                            }%`,
                          }}
                        >
                          <img
                            src={thumbnail_url.image_url}
                            alt={title}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "10px 10px 0 0",
                              display: "block",
                            }}
                            loading="lazy"
                          />
                        </div>
                      </DesignItemInner>
                    </DesignItem>
                  )
                )}
              </Masonry>
            </DesignsWrapper>
          </TabPanel>
          <TabPanel key={"communication"}>
            <DesignsWrapper>
              <Masonry className="masonryGrid" options={masonryOptions}>
                {filteredCommunicationDesigns.map(
                  ({ title, link, thumbnail_url }, index) => (
                    <DesignItem
                      key={index}
                      onClick={() => {
                        navigate(`/designs/${link}`);
                      }}
                    >
                      <DesignItemInner>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            paddingBottom: `${
                              (thumbnail_url.height / thumbnail_url.width) * 100
                            }%`,
                          }}
                        >
                          <img
                            src={thumbnail_url.image_url}
                            alt={title}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "10px 10px 0 0",
                              display: "block",
                            }}
                            loading="lazy"
                          />
                        </div>
                      </DesignItemInner>
                    </DesignItem>
                  )
                )}
              </Masonry>
            </DesignsWrapper>
          </TabPanel>
          <TabPanel key={"media"}>
            <DesignsWrapper>
              <Masonry className="masonryGrid" options={masonryOptions}>
                {filteredMediaDesigns.map(
                  ({ title, link, thumbnail_url }, index) => (
                    <DesignItem
                      key={index}
                      onClick={() => {
                        navigate(`/designs/${link}`);
                      }}
                    >
                      <DesignItemInner>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            paddingBottom: `${
                              (thumbnail_url.height / thumbnail_url.width) * 100
                            }%`,
                          }}
                        >
                          <img
                            src={thumbnail_url.image_url}
                            alt={title}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "10px 10px 0 0",
                              display: "block",
                            }}
                            loading="lazy"
                          />
                        </div>
                      </DesignItemInner>
                    </DesignItem>
                  )
                )}
              </Masonry>
            </DesignsWrapper>
          </TabPanel>
        </ReactTabs>
        <SectionHeader>
          {isLatest ? (
            <Link href="/designs" className="view-all">
              {t("designs.viewAll")}
              <Icon icon={arrowRight} className="view-all-icon" />
            </Link>
          ) : null}
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

Designs.propTypes = {
  isLatest: PropTypes.bool,
};

Designs.defaultProps = {
  isLatest: true,
};

export default Designs;
